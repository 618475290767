module.exports = [{
      plugin: require('/Users/damianbaar/workspace/kam-baar-site/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://kamillabaar.pl"},
    },{
      plugin: require('/Users/damianbaar/workspace/kam-baar-site/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/damianbaar/workspace/kam-baar-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
