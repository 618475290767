// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-i-18-n-redirect-tsx": () => import("/Users/damianbaar/workspace/kam-baar-site/src/i18n/redirect.tsx" /* webpackChunkName: "component---src-i-18-n-redirect-tsx" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/damianbaar/workspace/kam-baar-site/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-full-preview-tsx": () => import("/Users/damianbaar/workspace/kam-baar-site/src/templates/full-preview.tsx" /* webpackChunkName: "component---src-templates-full-preview-tsx" */),
  "component---src-pages-404-tsx": () => import("/Users/damianbaar/workspace/kam-baar-site/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/Users/damianbaar/workspace/kam-baar-site/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-gallery-tsx": () => import("/Users/damianbaar/workspace/kam-baar-site/src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/damianbaar/workspace/kam-baar-site/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-tsx": () => import("/Users/damianbaar/workspace/kam-baar-site/src/pages/live.tsx" /* webpackChunkName: "component---src-pages-live-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/damianbaar/workspace/kam-baar-site/.cache/data.json")

